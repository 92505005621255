import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';

const URL = "https://casting-api.palms.plus/api";
// const URL = process.env.REACT_APP_API_URL;

const authProvider = async (type, params) => {
    if (type === AUTH_LOGIN) {
        const { email, password } = params;
        const request = new Request(`${URL}/admin-user/login`, {
            method: 'POST',
            body: JSON.stringify({ email, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: "include",
        });
        
        return fetch(request)
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else if (response.status === 401) {
                    window.location.href = '/login/#/login';
                    return Promise.reject(new Error("Invalid email or password"));
                } else {
                    return Promise.reject(new Error("An unexpected error occurred"));
                }
            })
            .then(() => {
                return { redirectTo: '/' }; 
            })
            .catch(error => {
                // Връщане на грешка към `react-admin`, без логване в конзолата
                return Promise.reject(error);
            });
    }
    if (type === AUTH_LOGOUT) {
        const logoutRequest = new Request(`${URL}/admin-user/logout`, {
            method: 'POST',
            credentials: 'include',
        });

        return fetch(logoutRequest)
            .then(() => Promise.resolve());
    }
    if (type === AUTH_ERROR) {
        const status = params ? params.status : null;
        if (status === 401 || status === 403) {
            return Promise.reject();
        }
        return Promise.resolve();
    }
    if (type === AUTH_CHECK) {
        // console.log('%c🌴AUTH_CHECK--->',`color:aqua; font-size: 12px;`,  );
        const request = new Request(`${URL}/auth`, {
            credentials: "include"
        });
        return fetch(request)
            .then((res) => {
                if (res.status === 200) {
                    return res.json();
                }else if (res.status === 401 || res.status === 403) {
                    // Пренасочи към логин при неуспешна проверка на сесията
                    window.location.href = '/login/#/login';
                    return Promise.reject('Unauthorized access');
                } else {
                    return res.text().then((text) => Promise.reject(text));
                }
            })
            .then((user) => {
                if (user.roles) {
                    return Promise.resolve(user.roles);
                } else {
                    return Promise.reject("User roles not found");
                }
            })
            .catch((error) => {
                console.error("Authentication check failed:", error);
                return Promise.reject(error);
            });
    }
    
    if (type === AUTH_GET_PERMISSIONS) {
        const request = new Request(`${URL}/auth`, {
            credentials: "include"
        });
    
        return fetch(request)
            .then((res) =>
                res.status === 200
                    ? res.json()
                    : res.text().then((text) => Promise.reject(text))
            )
            .then((user) => {
                const { roles, email } = user;
                if (roles && email) {
                    return Promise.resolve({ roles, email });
                } else {
                    return Promise.reject("Roles or token missing");
                }
            })
            .catch((error) => {
                // console.error("Error in AUTH_GET_PERMISSIONS:", error);

                // Разлогване на потребителя при невалиден токен
                if (error.includes('Unauthorized') || error.includes('Invalid token')) {
                    window.location.href = '/login/#/login';
                }
    
                return Promise.reject(error);
            });
    }
    
    return Promise.resolve();
}

export default authProvider;
