// src/permissions.js
import AdminUsersList from "./components/user/AdminUsersList";
import AdminUserCreate from "./components/user/AdminUserCreate";
import AdminUserEdit from "./components/user/AdminUserEdit.js";
import UsersList from "./components/user/UsersList";
import UserShow from "./components/user/UserShow";
import WomenGame from "./components/women-game/WomenGame.jsx";
import PalmsFun from "./components/palms-fun/PlamsFun.jsx";

// Определяне на ресурсите въз основа на ролите
const roleBasedResources = {
  admin: [
    { name: "admin-user", component: AdminUsersList, create: AdminUserCreate , edit:AdminUserEdit},
    { name: "user", component: UsersList, show: UserShow },
    { name: "women-game", component: WomenGame },
    { name: "palms-fun", component: PalmsFun },
  ],
  "admin-casting": [
    { name: "user", component: UsersList, show: UserShow },
  ],
  "admin-prices": [
    { name: "women-game", component: WomenGame},
    { name: "palms-fun", component: PalmsFun},
  ],
};

export default roleBasedResources;
