import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { TextField, Button, Container, Typography, Box } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import DropdownBrandMenu from "../women-game/DropdownBrandMenu";

function PalmsFun() {
   const [brand, setBrand] = useState("bg");
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      videoUrl: "",
      prizeName: "",
      userId: "ID:",
      drawDate: "",
      fileName: "palms-fun.json",
      brand: brand,
    },
  });

  const onSubmit = async (formData) => {
    try {
      const response = await fetch("https://casting-api.palms.plus/api/sftp/upload", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      toast.success(data.message, {
        position: "bottom-right",
      });
      console.log("Data submitted successfully:", formData);
    } catch (error) {
      toast.error("Upload failed. Please try again.", {
        position: "bottom-right",
      });
      console.error("Error submitting data:", error);
    }
  };

  return (
    <Container maxWidth="sm">
      <DropdownBrandMenu name="brand" control={control} onChange={setBrand} />
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          PALM FUN WINNERS
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller name="videoUrl" control={control} render={({ field }) => <TextField label="Youtube" placeholder="Youtube embed code" fullWidth margin="normal" required {...field} />} />
          <Controller name="prizeName" control={control} render={({ field }) => <TextField label="Prize Name" fullWidth margin="normal" required {...field} />} />
          <Controller name="userId" control={control} render={({ field }) => <TextField label="User ID" fullWidth margin="normal" required {...field} />} />
          <Controller name="drawDate" control={control} render={({ field }) => <TextField label="Draw Date" placeholder="dd.mm.yyyy" fullWidth margin="normal" required {...field} />} />
          <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
            Submit
          </Button>
        </form>
      </Box>
      <ToastContainer />
    </Container>
  );
}

export default PalmsFun;
